/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { ComSvgs } from "./svgs"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
// import SideBar from "./sideBar"
import "./css/layout.css"
import "./css/common.css"

const Layout = function({ children, cur, showSBar, bg, stickyHeader }) {
  return (
    <>
      <ComSvgs />
      <Header cur={cur} sticky={stickyHeader} />
      <main style={{backgroundColor: bg || '#fff'}}>{children}</main>
      <Footer />
      {/* {showSBar && <SideBar wrapClass="fixRight" />} */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
