import { Link } from "gatsby"
import React from "react"

import { CDNBASE } from "../utils/maps"
import st from './css/footer.module.scss'


const Footer = () => (
  <footer className={st.footer}>
    <div className={st.cols}>
      <dl>
        <dt>常用链接</dt>
        <dd>
          <p><Link to='/' >酷课功能介绍</Link></p>
          <p><Link to='/advance/' >我们的优势</Link></p>
          <p><Link to='/cases/' >用户使用案例</Link></p>
          <p><Link to='/FQ.html' >常见问题</Link></p>
          <p><Link to='/agreement.html' >酷课服务协议</Link></p>
        </dd>
      </dl>
      <dl>
        <dt>联系我们</dt>
        <dd>
          <address>
            <p><a href="tel:13437128855">酷梅：134 3712 8855</a></p>
            <p><a href="tel:15814401379">小静：159 2720 4971</a></p>
            <p><a href="mailto:jason@7xingchong.com">meiyouqing@126.com</a></p>
          </address>
        </dd>
      </dl>
      <dl>
        <dt>酷课公众号</dt>
        <dd><img alt="酷课公众号" src={CDNBASE + '/src/qrcode/gzh_dark.gif'} /></dd>
      </dl>
      <dl>
        <dt>会员端演示</dt>
        <dd><img alt="注册试用" src={CDNBASE + '/src/qrcode/field_demo_dark.gif?001'} /></dd>
      </dl>
      <dl>
        <dt>注册试用</dt>
        <dd><img alt="注册试用" src={CDNBASE + '/src/qrcode/user_dark.gif'} /></dd>
      </dl>
      <dl>
        <dt>客服微信</dt>
        <dd><img alt="客服微信" src={CDNBASE + '/src/qrcode/kumei_dark.gif'} /></dd>
      </dl>
    </div>
    <div className={st.divider}></div>
    <div className={st.links}>
      <Link to='https://dc.kukecloud.com' >酷课约课系统</Link>
      <Link to='https://fw.kukecloud.com' >酷课服务预约系统</Link>
    </div>
    <div className={st.copyright}>
      <span><Link to=''>粤ICP备18068359号</Link></span>
      <span>©2017~2020 酷课场地预订系统 kukecloud.com</span>
    </div>
  </footer>
)

export default Footer
