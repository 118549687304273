export const CDNBASE = `https://cdn.kukecloud.com/assets/articles`;

export const catMap = {
  contract: '酷课协议',
  FAQ: '酷课问答',
  operation: '运营小窍门',
  kkguide: '酷课使用指南',
  upgrade: '酷课系统更新',
}

export const tagColorMap = {
  "酷课技能": '#ffd985',
  "运营技巧": '#70d4f3',
  "统计分析": '#ffadd2',
  "公众号": '#78cc99',
  "会员卡": '#ffa6b3',
  "训练营": '#adb1f2',
  "拓客": '#ffb58b',
  "会员管理": '#ff909b',
}