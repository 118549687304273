/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, keywords, lang, meta, title, author }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            copyright
          }
        }
      }
    `
  )

  const metaKeywords = [...new Set((keywords + site.siteMetadata.keywords).split(','))]
    .filter((v) => !!v)
    .join(',')
  const metaDescription = description || site.siteMetadata.description
  const metaAuthoor = author || site.siteMetadata.author

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: metaAuthoor,
        },
        {
          name: `copyright`,
          content: site.siteMetadata.copyright,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `zh`,
  meta: [],
  description: ``,
  keywords: ``,
}

SEO.propTypes = {
  keywords: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
