import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from 'classnames'

import { CDNBASE } from "../utils/maps"
import st from "./css/sideBar.module.scss"

const SideBar = ({ wrapClass, scrollTop }) => (
  <div className={classNames(st[wrapClass], {[st.show]: scrollTop > 500})}>
    <span className={st.iconWrap}>
      <svg className={st.barIcon}><use className={st.svgUse} xlink="https://www.w3.org/1999/xlink" href="#wechat"></use></svg>
      <div className={st.iDetail}>
        <img className={st.qrcode} src={CDNBASE + '/src/qrcode/gzh_dark.gif'} alt="酷课公众号" />
        <div><b>关注酷课公众号</b><br/>开始免费试用</div>
      </div>
    </span>
    <span className={st.iconWrap}>
      <svg className={st.barIcon}><use xlink="https://www.w3.org/1999/xlink" href="#minipro"></use></svg>
      <div className={st.iDetail}>
        <img className={st.qrcode} src={CDNBASE + '/src/qrcode/field_demo_dark.gif?001'} alt="酷课订场小程序码" />
        <div><b>会员端演示版</b><br/>微信扫码体验</div>
      </div>
    </span>
    <span className={st.iconWrap}>
      <svg className={st.barIcon}><use xlink="https://www.w3.org/1999/xlink" href="#servicer"></use></svg>
      <div className={classNames(st.iDetail, st.toleft)}>
        <img className={st.qrcode} src={CDNBASE + '/src/qrcode/kumei_dark.gif'} alt="酷课公众号" />
        <div><b>你的专属客服</b><br/>微信扫码添加</div>
      </div>
    </span>
    <span className={st.iconWrap}>
      <svg className={st.barIcon}><use xlink="https://www.w3.org/1999/xlink" href="#contact"></use></svg>
      <div className={classNames(st.iDetail, st.toleft)} style={{textAlign: 'left'}}>
        <h4>客服电话：</h4>
        <h5>酷梅：134 3712 8855</h5>
        <h5>小静：159 2720 4971</h5>
      </div>
    </span>
    <Link className={st.go} target="_blank" to="https://dc.kukecloud.com/adm/">开始使用</Link>    
  </div>
)

SideBar.propTypes = {
  wrapClass: PropTypes.string,
  scrollTop: PropTypes.number,
}

SideBar.defaultProps = {
  wrapClass: `inNavBar`,
  scrollTop: 0,
}

export default SideBar;